
	@import "./src/assets/styles/color.scss";
  /* stylelint-disable indentation */
  #system, #dist, #kampid, #kamp_span, #preheader, #search, #replace, #bgcolor, #fontcolor, #trackinglinks  {
    background-color: #b9a28a;
    border-color: #6c553c;

  }
	.row {
		margin-bottom: 8px;
	}

  #customfields_var_ms, #customfields_var, #customfields_cf  {
    border-style: solid;
    border-width: 2px;
    border-color: #6c553c;
   }


  #systemtext, #disttext {
    font-size: 18px;
    font-weight: bold;
    color: #f8e8d2;
    text-shadow: #303030 1px 1px;
  }


	textarea {
		width: 100%;
    background-color: #b9a28a;
		&#import {
			height: 160px;
		}
	}
	label {
		text-align: left;
		display: block;
	}
	iframe {
		&.active {
			width: 100%;
			min-width: 600px;
			height: 600px;
		}
	}
	.customfields div[class*="col-"],
	.variablefields div[class*="col-"] {
		margin: 4px 8px;
		border: 1px solid $tiles;
	}
	.mr-auto {
		margin-right: auto;
	}
	input[type=checkbox] {
		margin: 10px;
		transform: scale(1.5);
	}
	.options div[class*="col-"] > .btn {
		margin: 22px 0 0;
	}
	div[class*="col-"].center {
		& > label {
			text-align: center;
		}
		& > input {
			margin-left: auto;
			margin-right: auto;
		}
	}
	.trackinglinks {
		select {
			padding: 0.375rem 0.75rem;
			height: 38px;
		}
	}
